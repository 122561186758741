import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookiesConsent = () => (
  <CookieConsent enableDeclineButton>
    This website uses cookies to enhance the user experience.{' '}
    <a href='/cookie-policy'>Cookie Policy</a> |{' '}
    <a href='/privacy-policy'>Privacy Policy</a> |{' '}
    <a href='/termsandconditions'>Terms and conditions</a>
  </CookieConsent>
)

export default CookiesConsent
